import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Jerk 4×4\\@75%1RM`}</p>
    <p>{`Band Pull Aparts 4×10`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time:`}</p>
    <p>{`20 Calorie Ski Erg`}</p>
    <p>{`15 T2B`}</p>
    <p>{`10 Clean & Jerks (135/95)(RX+ 155/105)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our free Thanksgiving Day workout is Thursday at 9:00am. If you’d
like to attend and aren’t a member just email:
Daniel\\@crossfittheville.org.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      